export default {
	"10_0": "Osiadający bardzo wolno, organiczny i / lub nieorganiczny pył zwany pyłem zawieszonym o średnicy ziaren <b>10 µm</b>. Przykłady pyłu PM 10 nieorganicznego: <b>popiół lotny, pył inhalabilny docierający do górnych dróg oddechowych i płuc</b>. Przykłady pyłu PM 10 organicznego: <b>zarodniki grzybów, pyłki kwiatowe.</b>",
	"2_5": "Osiadający bardzo wolno, organiczny i / lub nieorganiczny pył zwany pyłem zawieszonym o średnicy ziaren <b>2,5 µm</b>. Przykłady pyłu PM 2.5 nieorganicznego: <b>azbest, popiół lotny, sadza, pył respirabilny docierający bezpośrednio do pęcherzyków płucnych</b>. Przykłady pyłu PM 2.5 organicznego: <b>bakterie, zarodniki grzybów</b>.",
	"1_0": "Osiadający bardzo wolno, organiczny i / lub nieorganiczny pył zwany pyłem zawieszonym o średnicy ziaren <b>1µm</b>. Przykłady pyłu PM 1.0 nieorganicznego: <b>dym papierosowy, azbest, sadza wynikająca ze spalin samochodowych (olej), docierający bezpośrednio przez płuca do krwioobiegu a następnie do innych narządów</b>. Przykłady pyłu PM 1.0 organicznego: <b>bakterie, wirusy</b>.",
	"o3": "<b>O3 - Ozon troposferyczny - silnie toksyczna trójatmowa cząsteczka tlenu</b>. Tworzy się w powietrzu w wyniku reakcji tlenków azotu (emitowane przez m.in. transport kołowy oraz przemysł) i lotnych związków organicznych pod wpływem słońca. Ozon ma negatywny wpływ na układ nerwowy oraz oddechowy w szczególności może się przyczynić do zwężenia oskrzelików i zwłóknienia pęcherzyków płucnych. Zwiększone stężenie ozonu może doprowadzić także do zapalenia górnych dróg oddechowych, zapalenia płuc, a u osób starszych zawału serca. Dłuższe narażenie na zwiększone stężenie ozonu może skutkować astmą, udarem mózgu lub nowotworem płuc.",
	"no2": "<b>NO2 - Dwutlenek azotu - rakotwórczy i trujący gaz</b>. Powstaje przede wszystkim w wyniku wysokotemperaturowego spalania paliw, emitowany najczęściej przez transport kołowy, morski i lotniczy. Zwiększone stężenie może przyczynić się do uszkodzenia układu oddechowego, a długotrwałe oddziaływanie tego zanieczyszczenia na organizm ludzki może spowodować choroby serca i układu krążenia.",
	"so2": "<b>SO2 - Dwutlenek siarki - trujący gaz</b>. Powstaje przede wszystkim w wyniku spalania paliw kopalnych np. w kotłowniach, piecach grzewczych i zakłady przemysłowych. Powoduje u ludzi m.in. choroby alergiczne, podrażnienia błon śluzowych, skóry, spojówek oraz górnych dróg oddechowych.",
	"co2": "<b>CO2 - Dwutlenek węgla</b>. Gaz cieplarniany powstały w procesie spalania np. paliw kopalnych (węgiel, gaz, ropa). Nadmiar dwutlenku węgla w atmosferze doprowadza do zakwaszania wód gruntowych oraz wywołuje tak zwany \"efekt cieplarniany\" co skutkuje podnoszeniem się stanu wód morskich, zanikiem lodowców oraz wymieraniem wielu gatunków roślin i zwierząt.U człowieka zmniejsza sprawność intelektualną, powoduje zaburzenia równowagi kwasowo-zasadowej krwi, bóle głowy, wzrost ciśnienia tętniczego krwi i przyspieszenie akcji serca.",
	"f": "<b>HCHO - Silnie toksyczny formaldehyd mrówkowy</b>. Ulatniania się między innymi z klejów, farb, lakierów, lub podczas spalania drewna lakierowanego, płyt wiórowych, tekstyliów.",
	"ch2o": "<b>HCHO - Silnie toksyczny formaldehyd mrówkowy</b>. Ulatniania się między innymi z klejów, farb, lakierów, lub podczas spalania drewna lakierowanego, płyt wiórowych, tekstyliów."
}

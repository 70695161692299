import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';


const mapStateToProps = (state) => ({
  info: state.main.info,
  onClick: state.main.onClickInfo,
  colors: state.colors,
});


export default connect(mapStateToProps)(props => {

  const { info, colors, onClick } = props;

  const click = useCallback(() => onClick());

  return (
    <div
      className='rwdg-info-container'
      style={{
        borderColor: colors.foreground + '80',
        borderWidth: '1px',
        borderStyle: 'solid',
        display: info ? 'flex' : 'none'
      }}
    >
      {info ? <p
        className='rwdg-info-text'
        style={{ color: colors.foreground }}
        onClick={onClick}
      >
        {info}
      </p> : null}
    </div>
  );

});

export default {
  background: "#8ec5ff",
  background2: "#ffffff",
  background3: "#dfebff",
  background4: "#007bff",
  pieBackground: "#dfebff",
  foreground: "#12486e",
  foreground2: "#007bff",
  accent: '#00f8',
  accentFull: '#00f',
  blue: "#8ec5ff",
  lightblue: "#dfebff",
  green: "#88ac4f",
  greenLight: "#bac854",
  yellow: "#ebde57",
  orange: "#bb8637",
  red: "#a73224",
  brown: "#712a1f",
  darkblue: "#007bff",
  transBlue: "#8ec5ff60",
  redOrange: "#ff6347",
  white: "#ffffff",
  transWhite: "#ffffff60",
  dark: "#12486e",
  transDark: "#12486ed0",
  transLoadingDark: "#12486e90",
  gray : "#aaaaaa",
  darkgray : "#606060",
  lightgray: "#f8f9fa",
  dropBlue0: '#3000ff',
  dropBlue1: '#3026ff',
  dropBlue2: '#3053ff',
  dropBlue3: '#3079ff',
  dropBlue4: '#30a0ff',
  dropBlue5: '#30c0ff',
};

import React, { useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import './styles/main.scss';
import './styles/modal.scss';
import './styles/icons.scss';
//import './fontello/css/icons.css'
//import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import rootReducer from './reducers';
import Loading from 'cps/loading';
import Info from 'cps/info';
import Modal from 'cps/modal';
import ElementList from 'cps/elementList';
import Updater from 'cps/updater';
import { BannerImage, Banner, BannerImageLogo, BannerTime, BannerName } from 'cps/banner';
import LoadParams from 'cps/loadParams';


window.prnt = (...args) => {
  //if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    console.log(...args);
  //}
}


const store = createStore(rootReducer);

const App = props => {

  const [ready, setReady] = useState(false);

  const onLoad = useCallback(data => {
    setReady(data)
  }, [ready]);

  return (

      <div className='rwdg-main-container'>
        <LoadParams component={props.component} funData={props.config || {}} onLoad={onLoad} />
        {ready ? <>
          <Updater />
          <BannerImage />
          <BannerName />
          <BannerTime />
          <Banner />
          <ElementList />
          <BannerImageLogo />
          <Info />
          <Loading />
          <Modal />
        </> : null}
      </div>

  )
};


const comp = document.getElementById('air-sensor-widget');
if (comp !== null) {
  const store = createStore(rootReducer);
  ReactDOM.render(
    (
      <Provider store={store}>
        <App component={comp} />
      </Provider>
    ),
    comp
  );
}


window.ASWidget = function(process, data) {
  const store = createStore(rootReducer);
  const configData = JSON.parse(JSON.stringify(data));
  prnt('configData', configData)
  const component = document.getElementById(configData.mountNode);
  if (component !== null) {
    ReactDOM.render(
      (
        <Provider store={store}>
          <App component={component} config={configData} />
        </Provider>
      ),
      component
    );
  }
};

import React, { useState, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import Icon from './icon';
import { VictoryPie } from 'victory';
import { setMainState } from 'src/actions';
import info from '../infoTab';


const mapStateToProps = state => ({
	colors: state.colors,
});

const mapDispatchToProps = dispatch => ({
  setModal: modal => dispatch(setMainState({ modal })),
});


export default connect(mapStateToProps, mapDispatchToProps)(props => {
	const { label, color, val, val2, percent, percentVisible, undef, colors, maxW, infoKey, setModal } = props;

	const [opacity, setOpacity] = useState(isMobile ? 0.5 : 0);

	const setHalfHelpOpacity = useCallback(() => setOpacity(isMobile ? 0.5 : 0.3), [opacity]);
	const setNullHelpOpacity = useCallback(() => setOpacity(isMobile ? 0.5 : 0), [opacity]);
	const setFullHelpOpacity = useCallback(() => setOpacity(isMobile ? 0.5 : 1), [opacity]);

	const getModalContent = useMemo(() => ({ __html: info[infoKey] }), [info, infoKey]);

	const showModal = useCallback(() => {
		setModal({
			text: ' ',
			content: <div className='rwdg-modal-content-container'>
				<Icon
					name={'\uf341'}
					color={colors.white}
					size={50}
				/>
				<div className='rwdg-modal-subelement-container'>
					<p className='rwdg-modal-subelement-text' dangerouslySetInnerHTML={getModalContent} />
				</div>
			</div>
		})
	}, [ colors, getModalContent ]);

	return (
		<div className='rwdg-pie-container' onMouseLeave={setNullHelpOpacity}>
			<div onMouseEnter={setHalfHelpOpacity}>
				<div className='rwdg-pie-text-container' style={{ width: maxW-10, height: maxW-10 }}>
					<p className='rwdg-chart-pie-text' style={{ color: colors.foreground2, marginBottom: 5, marginTop: 5 }}>
						{undef ? 'BRAK' : `${val}${'\n'}${label}`}
					</p>
					{(percentVisible || undef) ? <p className='rwdg-chart-pie-text' style={{ marginTop: 2, marginBottom: 5, color: colors.foreground2 }}>
						{undef ? 'DANYCH' : val2}
					</p> : null}
				</div>
				<VictoryPie
					animate={{ duration: 1000 }}
					angle={180}
					padding={{ top: 0, left: 0, right: 0, bottom: 0 }}
					padAngle={0}
					innerRadius={(percent > 100 || undef) ? 0 : ((maxW/2)-15)}
					width={maxW}
					height={maxW}
					data={[{'key': "", 'y': undef ? 100 : percent}, {'key': "", 'y': undef ? 0 : (100-percent)} ]}
					colorScale={[ undef ? '#ccc' : color, colors.pieBackground ]}
					labels={() => ('')}
				/>
			</div>
			{infoKey ? <div className='rwdg-pie-help' style={{ opacity }} onMouseEnter={setFullHelpOpacity} onClick={showModal}>
				<Icon name={'\uf342'} color={colors.foreground2} size={30} style={{ marginRight: 10 }} />
			</div> : null}
		</div>
	);
});

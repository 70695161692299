import React, { useEffect, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { setMainState } from 'src/actions';
import df from 'dateformat';
import SinglePie from './singlePie';


const mapStateToProps = state => ({
  properties: state.main.properties,
  rawData: state.main.rawData,
  types: state.main.types,
  charts: state.main.charts,
  ecoMode: state.main.ecoMode,
  colors: state.colors,
})

const mapDispatchToProps = dispatch => ({
  setLoading: loading => dispatch(setMainState({ loading })),
  setInfo: info => dispatch(setMainState({ info })),
  setReload: reload => dispatch(setMainState({ reload })),
  setCharts: charts => dispatch(setMainState({ charts })),
})



export default connect(mapStateToProps, mapDispatchToProps)(props => {
  const { setLoading, setInfo, setReload, properties, rawData, ecoMode, charts, setCharts, colors, types } = props
  const { gs } = properties;

  const maxW = useMemo(() => (120), []);

  const grayScale = useCallback((val, name) => {
    const n = ecoMode ? 80 : 176;
    const x = ecoMode ? 240 : 32;
    let op = n;
    if (gs[name].type === 'along') {
      if (val > gs[name].max) op = x;
      else if (val < gs[name].min) op = n;
      else op = (((x - n) * (val - gs[name].min))/(gs[name].max - gs[name].min)) + n
    }
    else if (gs[name].type === 'mirror') {
      if (val > gs[name].max || val < gs[name].min) op = x
      else {
        if (val === gs[name].zero) op = n
        else if (val < gs[name].zero) {
          op = (((x - n) * (val - gs[name].zero))/(gs[name].min - gs[name].zero)) + n
        }
        else {
          op = (((x - n) * (val - gs[name].zero))/(gs[name].max - gs[name].zero)) + n
        }
      }
    }
    op = parseInt(op);
    return `rgb(${op}, ${op}, ${op})`;
  }, [ecoMode]);


  const coloring = useCallback((val, name) => {
    for (let i = 0; properties.tresholds[name].length > i; i++) {
      if (['t', 'h', 'p'].indexOf(name) !== -1) return grayScale(val, name)
      else if (val < properties.tresholds[name][i]) return properties.colors[name][i];
    }
    if (val >= properties.tresholds[name][properties.tresholds[name].length - 1]) return properties.colors[name][properties.colors[name].length - 1];
    return '#ddd';
  }, [grayScale]);


  const calcAbsolute = useCallback((val, name) => {
    if (['t', 'p', 'h'].indexOf(name) !== -1) return '';
    return `${Math.round(val * properties.relativeParser[name])}%`;
  }, []);


  const parseKeys = useCallback(obj => {
    let rtn = {};
    obj = obj.filter(item => item.type in properties.keysParser);
    obj.forEach(item => rtn[properties.keysParser[item.type]] = item.score)
    return rtn;
  }, [])


  const parseData = useCallback((history, update) => {
    /*
    sensor:
    history: [
      {2019-08-11 11:00: {pm_10_0: 40, pm_2_5: 34, pm_1_0: 12, temperature: 35, pressure: 1020, humidity: 29}}
    ]

    return [
      {
				type: '1_0',
        name: 'PM 1',
        label: 'µg/m³',
        coloring: val => this.coloring(val, '2_5'),
        absolute: val => this.calcAbsolute(val, '2_5'),
        max: this.props.state.properties.ranges['2_5'].max,
        min: this.props.state.properties.ranges['2_5'].min,
        showPercent: true,
        data: [
          { x: new Date(2019, 3, 1, 10, 0), y: 10 },
          ...
        ]
      },...
    */
    const dateLabels = history ? Object.keys(history) : [];
    const rawNames = history ? properties.keys.filter(it => Object.keys(history[dateLabels[0]]).indexOf(it) !== -1) : [];
    const dataNames = history ? rawNames.map(item => properties.keysParser[item]) : [];
    let rtn = [];
    for (let i = 0; rawNames.length > i; i++) {
      rtn.push({
				info: properties.infoKeys[dataNames[i]],
        name: properties.namesParser[dataNames[i]],
        label: properties.unitParser[dataNames[i]],
        coloring: val => coloring(val, dataNames[i]),
        absolute: val => calcAbsolute(val, dataNames[i]),
        showPercent: properties.isPercent[dataNames[i]],
        max: properties.ranges[dataNames[i]].max,
        min: properties.ranges[dataNames[i]].min,
        data: []
      })
      for (let j = 0; dateLabels.length > j; j++) {
        if (rtn.length > 0) {
          rtn[rtn.length - 1].data.push({ x: parseDate(dateLabels[j]), y: history[dateLabels[j]][rawNames[i]], y0: dataNames[i] === 'p' ? 1013 : 0 });
        }
      }
      if (rtn.length > 0) {
        rtn[rtn.length - 1].data.sort((a, b) => { return (b.x.getTime() < a.x.getTime()) ? 1 : -1 } );
      }
    }
    update(rtn);
  }, [ coloring ]);


  useEffect(() => {
    //setTimeout(() => setLoading('Ładuję...'), 1000);
    //setTimeout(() => setLoading(''), 2000);
    //setTimeout(() => setInfo('testowy jakiś tekst'), 3000);
    //setTimeout(() => setInfo(''), 4000);
    setReload(true);
  }, []);

  useEffect(() => {
    if (rawData) {
      const latestDate = parseIsoDate(rawData.date);
      parseData(
        rawData ? {[df(latestDate, 'yyyy-mm-dd HH:MM')]: parseLatestToHist(rawData.results.filter(item => (types.length === 0 || types.indexOf(item.type) !== -1)))} : undefined,
        rtn => {
          prnt('setCharts', rtn);
          setCharts(rtn);
        }
      );
    }
  }, [rawData]);


  return (
    <div className='rwdg-general-container'>
      {charts.map(item => {
        let undef = item.data.length === 0;
        undef = item.data[0].y === null ? true : undef
        const val = undef ? 0 : item.data[0].y
        return undef ? null : (
          <div key={`pie-${item.name}`} style={{ width: maxW }}>
            <div className='rwdg-title-container'>
              <p className='rwdg-title' style={{ color: colors.foreground }}>
                {item.name}
              </p>
            </div>
            <SinglePie
              label={item.label}
              color={item.coloring(val)}
              val={val}
              val2={item.absolute(val)}
              percent={percentVal(item.max, item.min, val)}
              percentVisible={item.showPercent}
              undef={undef}
              maxW={maxW}
							infoKey={item.info}
            />
        </div>
        );
      })}
    </div>
  )
})


const parseDate = strdt => {
  //2018-07-02 12:05
  const tm = strdt.split(' ')[1].split(':');
  const dt = strdt.split(' ')[0].split('-');
  return (dt.length >= 3 && tm.length >= 2) ? new Date(dt[0], dt[1]-1, dt[2], tm[0], tm[1]) : new Date();
};

const parseIsoDate = strdt => {
  //2018-07-02T12:05:12.432342
  const tm = strdt.split('T')[1].split(':');
  const dt = strdt.split('T')[0].split('-');
  return (dt.length >= 3 && tm.length >= 2) ? new Date(dt[0], dt[1]-1, dt[2], tm[0], tm[1]) : new Date();
};

const parseLatestToHist = latest => {
  let rtn = {};
  latest.forEach(item => rtn[item.type] = item.score)
  return rtn;
};

const percentVal = (max, min, val) => {
  return Math.round(((val - min) / (max - min)) * 100);
}

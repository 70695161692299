import initialColors from './staticColors';

const colors = (state = initialColors, action) => {
  switch (action.type) {
    case 'SET_COLOR_MODE':
      if (action.data) {
        return {
          ...state,
          background: "#2c2c2c",
          background2: "#2c2c2c",
          background3: "#aaaaaa",
          background4: "#2c2c2c",
          pieBackground: "#202020",
          foreground: "#9c9c9c",
          foreground2: "#cccccc",
          accent: '#fff8',
          accentFull: '#fff',
        }
      } else {
        return {
          ...state,
          background: "#8ec5ff",
          background2: "#ffffff",
          background3: "#dfebff",
          background4: "#007bff",
          pieBackground: "#dfebff",
          foreground: "#12486e",
          foreground2: "#007bff",
          accent: '#00f8',
          accentFull: '#00f',
        }
      }
    default:
      return state;
  }
};

export default colors;

import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { setMainState } from 'src/actions';
import df from 'dateformat';


const mapStateToProps = state => ({
  reload: state.main.reload,
  site: state.main.site,
  apiKey: state.main.apiKey,
  url: state.main.url,
  lookupId: state.main.lookupId,
  types: state.main.types,
  token: state.main.token,
});

const mapDispatchToProps = dispatch => ({
  setRawData: rawData => dispatch(setMainState({ rawData })),
  setLoading: loading => dispatch(setMainState({ loading })),
  setReload: reload => dispatch(setMainState({ reload })),
  setInfo: info => dispatch(setMainState({ info })),
  setClickInfo: onClickInfo => dispatch(setMainState({ onClickInfo })),
  setSensorName: sensorName => dispatch(setMainState({ sensorName })),
});


export default connect(mapStateToProps, mapDispatchToProps)(props => {

  const {
    setLoading,
    setRawData,
    state,
    site,
    apiKey,
    url,
    setInfo,
    reload,
    setReload,
    setClickInfo,
    types,
    lookupId,
    setSensorName,
    token,
  } = props;


  const setMeasure = useCallback(() => {
    setLoading('Pobieram dane...');
    if (!Boolean(lookupId)) {
      fetchTo({ url: `${url}/average`, site, apiKey, token,
        onSuccess: resp => {
          if (resp) {
            prnt('rawData', resp);
            setRawData(resp);
            setSensorName(undefined);
            prnt('ok')
            setLoading('');
          }
        },
        onError: err => {
          setInfo('Nie udało się pobrać danych z AirSensor.');
          setClickInfo(() => {
            setReload(true);
          })
          setLoading('');
        },
      })
    } else {
      fetchTo({ url: `${url}/sensors`, site, apiKey, token,
        onSuccess: resp => {
          if (resp) {
            const sensors = resp;
            let sensorId = sensors.filter(item => (item.sensor.address === lookupId));
            sensorId = sensorId.length ? sensorId[0].sensor.id : -1;
            if (sensorId !== -1) {
              fetchTo({ url: `${url}/sensor/${sensorId}`, site, apiKey, token,
                onSuccess: resp => {
                  if (resp) {
                    prnt('rawData', resp);
                    prnt('sensor name', resp.sensor.name);
                    //setRawData(resp.latest);
                    setRawData(parseHistory(resp.history));
                    setSensorName(resp.sensor.name);
                    prnt('ok')
                    setLoading('');
                  }
                },
                onError: err => {
                  setInfo('Nie udało się pobrać danych z AirSensor.');
                  setClickInfo(() => {
                    setReload(true);
                  })
                  setLoading('');
                },
              });
            } else {
              setInfo('Nie udało się pobrać danych z AirSensor. Podano niepoprawny adres EkoSłupka.');
              setClickInfo(() => {
                setReload(true);
              })
              setLoading('');
            }
          }
        },
        onError: err => {
          setInfo('Nie udało się pobrać danych z AirSensor.');
          setClickInfo(() => {
            setReload(true);
          })
          setLoading('');
        },
      })
    }

  }, [url, site, apiKey]);

  const resetReload = useCallback(() => setReload(false), [reload]);


  useEffect(() => {
    if (reload) {
      setInfo('');
      setClickInfo(() => {});
      setMeasure();
      resetReload();
    }
  }, [reload]);

  return null;
});



const fetchTo = ({url, site, apiKey, token, onSuccess, onError}) => {
  fetch(url, {
    method: 'GET',
    headers: { "Content-Type": 'application/json', site, apiKey, token }
  })
  .then(response => {
    prnt('fetch', response);
    if (response.status >= 400) {
      const err = new Error('fetch fuckup')
      err.response = response;
      throw err;
    }
    return response.json();
  })
  .then(resp => {
    prnt('fetch json', resp)
    onSuccess(resp)
  })
  .catch(err => {
    prnt('fetch error', err, err.response);
    onError(err);
  })
}


const parseHistory = data => {
  /*
  history: {
    2020-10-27 12:00: {
      carbon_dioxide: 563
      formaldehyde: 0
      pm_1_0: 1
      pm_2_5: 4
      pm_10_0: 5
    },
    2020-10-27 13:00: {
      carbon_dioxide: 566
      formaldehyde: 0
      pm_1_0: 1
      pm_2_5: 3
      pm_10_0: 4
    },
    ...
  }
  */
  if (Object.keys(data).length) {
    let date = new Date(Math.max(...Object.keys(data).map(i => (new Date(i)))))
    const results = [];
    const current = data[df(date, 'yyyy-mm-dd HH:MM')];
    if (current) {
      Object.keys(current).forEach(item => {
        results.push({
          score: current[item],
          type: item,
        });
      })
    } else {
      return undefined
    }
    date = df(date, 'isoDateTime');
    return { date, results }
  }
  return undefined
}

import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { setMainState } from 'src/actions';
import Loader from 'react-loader-spinner';



const mapStateToProps = (state) => ({
  loading: state.main.loading,
  colors: state.colors
});



export default connect(mapStateToProps)(props => {

  const { loading, colors } = props;

  const [ show, setShow ] = useState(false);

  const updateShow = useCallback(() => setShow(Boolean(loading)), [show, loading]);


  useEffect(() => {
    setTimeout(updateShow, 700);
  }, [loading]);


  return (
    <div
      className='rwdg-loading-main'
      style={{
        width: (loading || show) ? '100%' : '0%',
        height: (loading || show) ? '100%' : '0%',
        opacity: loading ? 1 : 0,
      }}>
      <div
        className='rwdg-loading-container'
        style={{ transform: [ { scale: (loading) ? 1 : 0} ], backgroundColor: colors.background + 'e0', borderColor: colors.foreground }}
      >
        {(loading && show) ? <p className='rwdg-loading-text' style={{ color: colors.foreground }}>
            {loading}
          </p> : null}
      </div>
    </div>
  );

});

import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { setMainState } from '../actions';


let timer1, timer2;


const mapStateToProps = (state) => ({
  state: state.main.modal,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  setState: modal => dispatch(setMainState({ modal }))
});



export default connect(mapStateToProps, mapDispatchToProps)(props => {

  const { state, setState } = props;

  const [view, setView] = useState(false);
  const [translateY, setTranslateY] = useState(-1000);
  const [scale, setScale] = useState(0); //1


  const toggle = useCallback(() => {
		const way = Boolean(!view);
    if (!way) {
      timer2 = setTimeout(() => {
				setView(false);
				setState({ text: '', content: null, time: 0 });
			}, 400);
      setTranslateY(-1000);
      setScale(0);
    } else {
      setView(true);
      setTranslateY(0);
      setScale(1);
    }
  }, [view, scale, timer2, state, translateY]);


  useEffect(() => {
    prnt(state)
    if (state.text) {
      toggle();
    }
    if (state.time) {
      timer1 = setTimeout(toggle, state.time);
    }
  }, [state]);

	useEffect(() => (() => {
		if (timer1 !== undefined) clearTimeout(timer1);
		if (timer2 !== undefined) clearTimeout(timer2);
	}), []);

  return (
    <>
      <div className='rwdg-modal-background' style={{ display: view ? 'flex' : 'none' }} onClick={toggle} />
      <div
        className='rwdg-modal-container'
        style={{ transform: `scale(${scale}) translateY(${translateY}px) translateX(-50%)` }}
      >
        {view ? (
          <div className='rwdg-modal-subcontainer'>
            {state.content}
            <p className='rwdg-modal-text'> {state.text} </p>
          </div>
        ) : null}
      </div>
    </>
  );
})

import React, { useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import { setMainState, setColors } from 'src/actions';


const mapStateToProps = state => ({
	state: state.main,
	ecoMode: state.main.ecoMode,
});

const mapDispatchToProps = dispatch => ({
	setState: data => dispatch(setMainState(data)),
	changeColors: data => dispatch(setColors(data)),
});


export default connect(mapStateToProps, mapDispatchToProps)(props => {
	const { state, setState, component, funData, onLoad, ecoMode, changeColors } = props;

	const data = useMemo(() => ({
		clientid: funData.clientid ? funData.clientid : component.getAttribute('clientid'),
		token: funData.token ? funData.token : component.getAttribute('token'),
		sensor: (funData.sensors && funData.sensors.length) ? funData.sensors[0] : funData.sensor ? funData.sensor : component.getAttribute('sensor'),
		types: funData.types ? funData.types : parseTypes(component.getAttribute('types')),
		site: component.getAttribute('site') || location.host,
		black: funData.black ? funData.black : component.getAttribute('black'),
		banner: funData.banner ? funData.banner : component.getAttribute('banner'),
		printName: funData.printName ? funData.printName : component.getAttribute('printName'),
		printTime: funData.printTime ? funData.printTime : component.getAttribute('printTime'),
		imageUrl: funData.imageUrl ? funData.imageUrl : component.getAttribute('imageUrl'),
		imageWidth: funData.imageWidth ? funData.imageWidth : component.getAttribute('imageWidth'),
		imageHeight: funData.imageHeight ? funData.imageHeight : component.getAttribute('imageHeight'),
		imageLink: funData.imageLink ? funData.imageLink : component.getAttribute('imageLink'),
	}), [funData]);

	useEffect(() => {
		prnt('set credentials', data);
		setState({
			site: data.site,
			apiKey: data.clientid,
			token: data.token,
			types: data.types,
			lookupId: data.sensor,
			ecoMode: data.black === 'false' ? false : Boolean(data.black),
			hasBanner: data.banner === 'false' ? false : Boolean(data.banner),
			printTime: data.printTime === 'false' ? false : Boolean(data.printTime),
			printName: data.printName,
			imageUrl: data.imageUrl ? data.imageUrl : (data.black === 'true' ? 'https://widget2.airsensor.eu/serve/EkoBannerDark.png' : 'https://widget2.airsensor.eu/serve/EkoBannerLight.png'),
			imageWidth: data.imageWidth ? data.imageWidth : "130",
			imageHeight: data.imageHeight ? data.imageHeight : "43",
			imageLink: data.imageLink ? data.imageLink : "https://e-gminy.pl",
		});
		onLoad(true);
	}, [data])

	useEffect(() => {
		changeColors(ecoMode);
	}, [ ecoMode ])

	return null
})

const parseTypes = inp => ((inp || inp !== null) ? inp.split('|').filter(item => Boolean(item)) : []);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import Icon from './icon';
import df from 'dateformat';
//import { ico } from 'statics';
import { setMainState } from 'src/actions';



const BannerImage = connect(state => ({ 
	url: state.main.imageLink,
	image: state.main.imageUrl,
	w: state.main.imageWidth,
	h: state.main.imageHeight
}))(({ 
	url,
	image,
	w,
	h,
}) => (
	<a href={url} className='rwdg-banner-image-container'>
		<img className='rwdg-banner-image' src={image} alt='bannerImage' width={w} height={h} />
	</a>
));

const BannerImageLogo = connect(state => ({ url: state.main.url, ecoMode: state.main.ecoMode  }))(props => (
	<a className='rwdg-link-image' href='https://e-gminy.pl' >
		<img src={`${props.url}/serve/eGminy${props.ecoMode ? 'Dark' : 'Light'}.png`} alt='eGminy' width='50' />
	</a>
));

const BannerName = connect(state => ({
	printName: state.main.printName,
	name: state.main.sensorName,
	color: state.colors.foreground,
}))(({name, color, printName}) => (printName && printName !== 'false') ? (
	<div className='rwdg-banner-image-container'>
		<p className='rwdg-name-text' style={{ color }}>
			{(printName === 'true' || printName === true) ? name : printName}
		</p>
	</div>
) : null);

const BannerTime = connect(state => ({
	printTime: state.main.printTime,
	color: state.colors.foreground,
	data: state.main.rawData,
}))(({data, color, printTime}) => (printTime && data) ? (
	<div className='rwdg-banner-image-container'>
		<p className='rwdg-time-text' style={{ color }}>
			{df(data.date, 'HH:MM dd.mm.yyyy')}
		</p>
	</div>
) : null);



class BannerCmp extends Component {
	constructor(props) {
		super(props);

		this.state = {
			iconName: '\uf29c',
			topText: 'brak danych',
			addText: 'Brak danych na temat jakości powietrza.',
			topTextColor: props.colors.white,
			topColor: props.colors.gray,
			addContent: () => (null),
		};

		this.showModal = this.showModal.bind(this);
	}

	componentDidMount() {
		this.setBanner();
	}

	shouldComponentUpdate(nextProps, nextState) {
		return [
			nextState !== this.state,
			nextProps.rawData !== this.props.rawData,
			nextProps.ecoMode !== this.props.ecoMode,
		].some(i => i);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.rawData !== this.props.rawData) {
			this.setBanner();
		}
		prnt('types', this.props.state.types)
	}

	showModal() {
		this.props.setModal({
			text: ' ',//String(this.state.addText),
			content: <div className='rwdg-modal-content-container'>
				<Icon
					name={this.state.iconName}
					color={this.state.topColor}
					size={50}
				/>
				<p style={{ fontSize: 24, fontWeight: 'bold', color: this.state.topColor, textTransform: 'uppercase' }}>
					{this.state.topText}
				</p>
				{this.state.addContent()}
			</div>
		})
	}

	getNumber(s) {
		return (s === '' || s === null) ? NaN : Number(s);
	}

	toMeas(raw) {
		prnt('raw', raw)
		let rtn = {};
		raw.forEach(item => { 
			rtn[this.props.state.properties.keysParser[item.type]] = item.score 
		});
		return rtn;
	}

	setBanner() {
		prnt('set banner this.props.rawData', this.props.rawData)
		if (this.props.rawData) {
			let tres = {}
			const meas = this.toMeas(this.props.rawData.results);
			prnt('meas', meas)
			const { tresholds } = this.props.state.properties;
			this.props.state.properties.bannerValues.forEach(item => {
				if (item in meas && item in tresholds) {
					switch (true) {
						case this.getNumber(meas[item]) < tresholds[item][0]:
							tres[item] = 0;
							break;
						case this.getNumber(meas[item]) < tresholds[item][1]:
							tres[item] = 1;
							break;
						case this.getNumber(meas[item]) < tresholds[item][2]:
							tres[item] = 2;
							break;
						case this.getNumber(meas[item]) < tresholds[item][3]:
							tres[item] = 3;
							break;
						case this.getNumber(meas[item]) < tresholds[item][4]:
							tres[item] = 4;
							break;
						case this.getNumber(meas[item]) >= tresholds[item][4]:
							tres[item] = 5;
							break;
						default:
							tres[item] = 6;
					}
				}
			})
			let rtn = Math.max(...Object.keys(tres).map(i => tres[i]));
			switch (rtn) {
				case 0:
					this.setState({
						iconName: '\uf1fe',
						topText: 'bardzo dobry',
						topTextColor: this.props.colors.white,
						topColor: this.props.colors.green,
						addText: 'Jakość powietrza jest bardzo dobra, zanieczyszczenie powietrza nie stanowi zagrożenia dla zdrowia, warunki bardzo sprzyjające do wszelkich aktywności na wolnym powietrzu, bez ograniczeń.',
						addContent: () => this.rActive(),
					})
					break;
				case 1:
					this.setState({
						iconName: '\uf206',
						topText: 'dobry',
						topTextColor: this.props.colors.dark,
						topColor: this.props.colors.greenLight,
						addText: 'Jakość powietrza jest zadowalająca, zanieczyszczenie powietrza powoduje brak lub niskie ryzyko zagrożenia dla zdrowia. Można przebywać na wolnym powietrzu i wykonywać dowolną aktywność, bez ograniczeń.',
						addContent: () => this.rActive(),
					})
					break;
				case 2:
					this.setState({
						iconName: '\uf594',
						topText: 'umiarkowany',
						topTextColor: this.props.colors.dark,
						topColor: this.props.colors.yellow,
						addText: 'Jakość powietrza jest akceptowalna. Zanieczyszczenie powietrza może stanowić zagrożenie dla zdrowia w szczególnych przypadkach (dla osób chorych, osób starszych, kobiet w ciąży oraz małych dzieci). Warunki umiarkowane do aktywności na wolnym powietrzu.',
						addContent: () => this.rNonActive(),
					})
					break;
				case 3:
					this.setState({
						iconName: '\uf591',
						topText: 'dostateczny',
						topTextColor: this.props.colors.white,
						topColor: this.props.colors.orange,
						addText: 'Jakość powietrza jest dostateczna, zanieczyszczenie powietrza stanowi zagrożenie dla zdrowia (szczególnie dla osób chorych, starszych, kobiet w ciąży oraz małych dzieci) oraz może mieć negatywne skutki zdrowotne. Należy rozważyć ograniczenie (skrócenie lub rozłożenie w czasie) aktywności na wolnym powietrzu, szczególnie jeśli ta aktywność wymaga długotrwałego lub wzmożonego wysiłku fizycznego.',
						addContent: () => (<>{this.rCritical()}{this.rDanger()}</>),
					})
					break;
				case 4:
					this.setState({
						iconName: '\uf228',
						topText: 'zły',
						topTextColor: this.props.colors.white,
						topColor: this.props.colors.red,
						addText: 'Jakość powietrza jest zła, osoby chore, starsze, kobiety w ciąży oraz małe dzieci powinny unikać przebywania na wolnym powietrzu. Pozostała populacja powinna ograniczyć do minimum wszelką aktywność fizyczną na wolnym powietrzu - szczególnie wymagającą długotrwałego lub wzmożonego wysiłku fizycznego.',
						addContent: () => (<>{this.rCritical()}{this.rDanger()}</>),
					})
					break;
				case 5:
					this.setState({
						iconName: '\uf225',
						topText: 'bardzo zły',
						topTextColor: this.props.colors.white,
						topColor: this.props.colors.brown,
						addText: 'Jakość powietrza jest bardzo zła i ma negatywny wpływ na zdrowie. Osoby chore, starsze, kobiety w ciąży oraz małe dzieci powinny bezwzględnie unikać przebywania na wolnym powietrzu. Pozostała populacja powinna ograniczyć przebywanie na wolnym powietrzu do niezbędnego minimum. Wszelkie aktywności fizyczne na zewnątrz są odradzane. Długotrwała ekspozycja na działanie substancji znajdujących się w powietrzu zwiększa ryzyko wystąpienia zmian m.in. w układzie oddechowym, naczyniowo-sercowym oraz odpornościowym.',
						addContent: () => (<>{this.rCritical()}{this.rDanger()}{this.rCriticalAlert()}</>),
					})
					break;
				default:
					this.setState({
						iconName: '\uf29c',
						topText: 'brak danych',
						topTextColor: this.props.colors.white,
						topColor: this.props.colors.gray,
						addText: 'Brak danych na temat jakości powietrza.',
						addContent: () => (null),
					})
			}
		}
	}

	rActive() {
		return (
			<div className='rwdg-modal-subelement-container'>
				<p className='rwdg-modal-subelement-text'>
					Spędzaj aktywnie czas na wolnym powietrzu bez ograniczeń
				</p>
				<div className='rwdg-modal-subelement-imagecontainer'>
					<img alt='info-icon' src={`${this.props.url}/serve/icons/run.png`} className='rwdg-banner-icon'/>
					<img alt='info-icon' src={`${this.props.url}/serve/icons/bic.png`} className='rwdg-banner-icon'/>
					<img alt='info-icon' src={`${this.props.url}/serve/icons/gym.png`} className='rwdg-banner-icon'/>
					<img alt='info-icon' src={`${this.props.url}/serve/icons/mother.png`} className='rwdg-banner-icon'/>
					<img alt='info-icon' src={`${this.props.url}/serve/icons/fam.png`} className='rwdg-banner-icon'/>
					<img alt='info-icon' src={`${this.props.url}/serve/icons/ball.png`} className='rwdg-banner-icon'/>
					<img alt='info-icon' src={`${this.props.url}/serve/icons/climb.png`} className='rwdg-banner-icon'/>
				</div>
			</div>
		)
	}

	rNonActive() {
		return (
			<div className='rwdg-modal-subelement-container'>
				<p className='rwdg-modal-subelement-text'>
					Unikaj wzmożonego wysiłku fizycznego na wolnym powietrzu
				</p>
				<div className='rwdg-modal-subelement-imagecontainer'>
					<img alt='info-icon' src={`${this.props.url}/serve/icons/norun.png`} className='rwdg-banner-icon'/>
					<img alt='info-icon' src={`${this.props.url}/serve/icons/nobic.png`} className='rwdg-banner-icon'/>
					<img alt='info-icon' src={`${this.props.url}/serve/icons/noball.png`} className='rwdg-banner-icon'/>
				</div>
			</div>
		)
	}

	rDanger() {
		return (
			<div className='rwdg-modal-subelement-container'>
				<p className='rwdg-modal-subelement-text'>
					Grupy szczególnego ryzyka
				</p>
				<div className='rwdg-modal-subelement-imagecontainer'>
					<img alt='info-icon' src={`${this.props.url}/serve/icons/old.png`} className='rwdg-banner-icon'/>
					<img alt='info-icon' src={`${this.props.url}/serve/icons/kid.png`} className='rwdg-banner-icon'/>
					<img alt='info-icon' src={`${this.props.url}/serve/icons/kids.png`} className='rwdg-banner-icon'/>
					<img alt='info-icon' src={`${this.props.url}/serve/icons/heart.png`} className='rwdg-banner-icon'/>
					<img alt='info-icon' src={`${this.props.url}/serve/icons/preg.png`} className='rwdg-banner-icon'/>
					<img alt='info-icon' src={`${this.props.url}/serve/icons/pull.png`} className='rwdg-banner-icon'/>
				</div>
			</div>
		)
	}

	rCritical() {
		return (
			<div className='rwdg-modal-subelement-container'>
				<p className='rwdg-modal-subelement-text'>
					Ogranicz do minimum przebywanie na zewnątrz szczególnie jeśli należysz do grupy ryzyka. Korzystaj z transportu publicznego. Nie wietrz mieszkania. Nie pal w kominku. Używaj maseczki przeciwsmogowej.
				</p>
				<div className='rwdg-modal-subelement-imagecontainer'>
					<img alt='info-icon' src={`${this.props.url}/serve/icons/owen.png`} className='rwdg-banner-icon'/>
					<img alt='info-icon' src={`${this.props.url}/serve/icons/nocar.png`} className='rwdg-banner-icon'/>
					<img alt='info-icon' src={`${this.props.url}/serve/icons/bus.png`} className='rwdg-banner-icon'/>
					<img alt='info-icon' src={`${this.props.url}/serve/icons/mask.png`} className='rwdg-banner-icon'/>
				</div>
			</div>
		)
	}

	rCriticalAlert() {
		return (
			<div className='rwdg-modal-subelement-container'>
				<p className='rwdg-modal-subelement-text' style={{ fontSize: 24, textTransform: 'uppercase' }}>
					Nie wychodź z domu!
				</p>
			</div>
		)
	}


	render() {
		const { ecoMode } = this.props;
		return (this.props.state.hasBanner && this.state.topText !== 'brak danych') ? (
			<div className='rwdg-banner' style={{ backgroundColor: this.state.topColor }} onClick={this.showModal} >
				<Icon name={this.state.iconName} color={this.state.topTextColor} size={20} style={{ marginRight: 10 }} />
				<p className='rwdg-banner-top-text' style={{ color: this.state.topTextColor }}>{this.state.topText}</p>
			</div>
		) : null;
	}
}


const mapStateToProps = (state) => ({
	state: state.main,
	ecoMode: state.main.ecoMode,
	colors: state.colors,
	rawData: state.main.rawData,
	url: state.main.url,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	setModal: modal => dispatch(setMainState({ modal })),
});


const Banner = connect(mapStateToProps, mapDispatchToProps)(BannerCmp);


export { Banner, BannerImage, BannerImageLogo, BannerTime, BannerName };

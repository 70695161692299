import colors from './staticColors';
import urls from '../../urls.json';

const isdev = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development');

const main = (state = {
	loading: '',
	site: '',
	apiKey: '',
	token: '',
	onClickInfo: () => {},
	lookupId: '',
	types: [],
	charts: [],
	url: urls ? (isdev ? `${urls.developmentProxy}:${urls.proxyPort}` : urls.productionProxy) : 'http://widget2.airsensor.eu',
	rawData: undefined,
	reload: false,
	info: '',
	ecoMode: false,
	modal: { text: '', content: null, time: 0 },
	hasBanner: false,
	printTime: undefined,
	printName: undefined,
	sensorName: undefined,
	imageUrl: 'https://widget2.airsensor.eu/serve/EkoBannerDark.png',
	imageWidth: "130",
	imageHeight: "43",
	imageLink: "https://e-gminy.pl",
	properties: {
		allValues: [
			'10_0',
			'2_5',
			'1_0',
			'o3',
			'no2',
			'so2',
			'c6h6',
			'co',
			'co2',
			'f',

			'no',
			'ch2o',
			'c6h6',
			'co',
			'ch4',
			'hcl',
			'hcn',
			'wt',
			'ph',
			'wo2',
			'db',
			'wf',
			'ws',
			'ec',
			'o2p',
			'cp',
			'tb',
			'alg',

			't',
			'h',
			'p',
		],
		infoKeys: {
			'10_0': '10_0',
			'2_5': '2_5',
			'1_0': '1_0',
			'o3': 'o3',
			'no2': 'no2',
			'so2': 'so2',
			'co2': 'co2',
			'f': 'f',
			'ch2o': 'ch2o',
		},
		bannerValues: [
			'10_0',
			'2_5',
			'1_0',
			'o3',
			'no2',
			'so2',
			'c6h6',
			'co',
			'co2',
			'f',
			'no',
			'ch2o',
			'c6h6',
			'co',
			'ch4',
		],
		values: [
			'10_0',
			'2_5',
			'1_0',
			'o3',
			'no2',
			'so2',
			'c6h6',
			'co',
			'co2',
			'f',
			
			'no',
			'ch2o',
			'c6h6',
			'co',
			'ch4',
			'hcl',
			'hcn',
			'wt',
			'ph',
			'wo2',
			'db',
			'wf',
			'ws',
			'ec',
			'o2p',
			'cp',
			'tb',
			'alg',
		],
		generalValues: [
			't',
			'h',
			'p'
		],
		keysParser: {
			'humidity': 'h',
			'temperature': 't',
			'pressure': 'p',
			'pm_1_0': '1_0',
			'pm_2_5': '2_5',
			'pm_10_0': '10_0',
			'soil_moisture': 'sm',
			'battery_level': 'b',
			'soilMoisture': 'sm',
			'batteryLevel': 'b',
			'formaldehyde': 'f',
			'nitrogen_dioxide': 'no2',
			'sulfur_dioxide': 'so2',
			'ozone': 'o3',
			'carbon_dioxide': 'co2',
			'water_level': 'wl',

			'nitrogen_monoxide': 'no',
			'methylene_oxide': 'ch2o',
			'c6h6': 'c6h6',
			'benzene': 'c6h6',
			'co': 'co',
			'carbon_monoxide': 'co',
			'methane': 'ch4',
			'hydrogen_chloride': 'hcl',
			'hydrogen_cyanide': 'hcn',
			'water_temperature': 'wt',
			'water_ph': 'ph',
			'water_oxygen': 'wo2',
			'noise': 'db',
			'water_flow': 'wf',
			'water_speed': 'ws',
			'electro_conduction': 'ec',
			'water_oxygen_percent': 'o2p',
			'chlorophyll': 'cp',
			'turbidity': 'tb',
			'blue_green_algae': 'alg',
		},
		labels: {
			'humidity': '%',
			'temperature': '°C',
			'pressure': 'hPa',
			'pm_1_0': 'PM1',
			'pm_2_5': 'PM2.5',
			'pm_10_0': 'PM10',
			'soil_moisture': '%',
			'battery_level': 'Ah%',
			'soilMoisture': '%',
			'batteryLevel': 'Ah%',
			'formaldehyde': 'HCHO',
			'nitrogen_dioxide': 'NO3',
			'sulfur_dioxide': 'SO2',
			'ozone': 'O3',
			'carbon_dioxide': 'CO2',
			'water_level': 'cm',

			'nitrogen_monoxide': 'no',
			'methylene_oxide': 'ch2o',
			'c6h6': 'c6h6',
			'benzene': 'c6h6',
			'co': 'co',
			'carbon_monoxide': 'co',
			'methane': 'ch4',
			'hydrogen_chloride': 'hcl',
			'hydrogen_cyanide': 'hcn',
			'water_temperature': 'wt',
			'water_ph': 'ph',
			'water_oxygen': 'wo2',
			'noise': 'db',
			'water_flow': 'wf',
			'water_speed': 'ws',
			'electro_conduction': 'ec',
			'water_oxygen_percent': 'o2p',
			'chlorophyll': 'cp',
			'turbidity': 'tb',
			'blue_green_algae': 'alg',
		},
		keys: [
			/*'pm_10_0',
			'pm_2_5',
			'pm_1_0',
			'nitrogen_dioxide',
			'sulfur_dioxide',
			'ozone',
			'carbon_dioxide',
			'formaldehyde',
			'soil_moisture',
			'battery_level',
			'soilMoisture',
			'batteryLevel',
			'temperature',
			'pressure',
			'humidity',
			'water_level',*/
			'pm_10_0',
			'pm_2_5',
			'pm_1_0',
			'nitrogen_monoxide',
			'nitrogen_dioxide',
			'sulfur_dioxide',
			'ozone',
			'co',
			'carbon_monoxide',
			'carbon_dioxide',
			'formaldehyde',
			'methylene_oxide',
			'c6h6',
			'benzene',
			'methane',
			'hydrogen_chloride',
			'hydrogen_cyanide',
			'water_temperature',
			'water_ph',
			'water_oxygen',
			'water_flow',
			'water_speed',
			'electro_conduction',
			'water_oxygen_percent',
			'chlorophyll',
			'turbidity',
			'blue_green_algae',
			'water_level',
			'soil_moisture',
			'noise',
			'battery_level',
			'temperature',
			'pressure',
			'humidity',
		],
		tresholds: {
			'1_0': [13, 35, 55, 75, 110],
			'2_5': [13, 35, 55, 75, 110],
			'10_0': [20, 50, 80, 110, 150],
			'sm': [10, 30, 50, 70, 90],
			'o3': [71, 121, 151, 181, 241],
			'no2': [140, 500, 900, 1300, 1500],
			'so2': [700, 1000, 1300, 2000, 2700],
			'c6h6': [6, 11, 16, 21, 51],
			'co': [3, 7, 11, 15, 21],
			'co2': [500, 1000, 5000, 9000, 27000],
			'f': [150, 197, 370, 540, 740],
			't': [-15, 0, 20, 35, 45],
			'h': [10, 30, 40, 60, 70, 90],
			'p': [1000, 1010, 1030, 1040, 1050],
			'b': [20, 50],
			'wl': [0, 1],
			
			'no': [140, 500, 900, 1300, 1500],
			'so2': [700, 1000, 1300, 2000, 2700],
			'c6h6': [6, 11, 16, 21, 51],
			'co': [2000, 6000, 10000, 14000, 20000],
			'co2': [500, 1000, 9000, 17000, 27000],
			'f': [150, 197, 370, 540, 740],
			'ch2o': [150, 197, 370, 540, 740],
			't': [-15, 0, 20, 35, 45],
			'h': [10, 30, 40, 60, 70, 90],
			'p': [1000, 1010, 1030, 1040, 1050],
			'b': [20, 50],
			'wl': [0, 1],
			'ch4': [ 0.5, 1, 1.5, 1.8, 2 ],
			'db': [30, 45, 60, 75, 90],
			'hcl': [ 12, 25, 50, 100, 200],
			'hcn': [ 2, 5, 10, 15, 20 ],
			'ph': [5, 6.5, 7.5, 9],
			'wo2': [ 6, 9, 11, 16 ],
			'wf': [200],
			'ws': [20],
			'wt': [5, 10, 15, 20, 25],
			'ec': [1000, 2000, 5000],
			'o2p': [25, 50, 75, 100],
			'cp': [70, 150, 400],
			'tb': [500, 1000],
			'alg': [200000], 
		},
		gs: {
			't': { type: 'mirror', min: -30, max: 40, zero: 0 },
			'h': { type: 'mirror', min: 0, max: 100, zero: 50 },
			'p': { type: 'mirror', min: 990, max: 1050, zero: 1019 },
			//'b': { type: 'along', min: 0, max: 100, zero: undefined }
		},
		ranges: {
			'1_0': { min: 0, max: 121 },
			'2_5': { min: 0, max: 121 },
			'10_0': { min: 0, max: 201 },
			'o3': { min: 0, max: 241 },
			'no2': { min: 0, max: 1500 },
			'so2': { min: 0, max: 2700 },
			'c6h6': { min: 0, max: 51 },
			'co': { min: 0, max: 21 },
			'co2': { min: 0, max: 9000 },
			'f': { min: 0, max: 740 },
			't': { min: -30, max: 70 },
			'h': { min: 0, max: 100 },
			'p': { min: 970, max: 1050 },
			'sm': { min: 0, max: 100 },
			'b': { min: 0, max: 100 },
			'wl': { min: 0, max: 1 },
			
			'no': { min: 0, max: 601},
			'ch2o': { min: 0, max: 740},
			'c6h6': { min: 0, max: 51},
			'co': { min: 0, max: 21000},
			'ch4': { min: 0, max: 3},
			'hcl': { min: 0, max: 200},
			'hcn': { min: 0, max: 20},
			'wt': { min: -5, max: 40},
			'ph': { min: 0, max: 14},
			'wo2': { min: 0, max: 30},
			'db': { min: 10, max: 120},
			'wf': { min: 0, max: 200},
			'ws': { min: 0, max: 20},
			'ec': { min: 0, max: 5000},
			'o2p': { min: 0, max: 100},
			'cp': { min: 0, max: 400},
			'tb': { min: 0, max: 1000},
			'alg': { min: 0, max: 200000},
		},
		relativeParser: {
			'2_5': 5,
			'10_0': 2,
			'o3': 0.83333,
			'no2': 2.5,
			'so2': 0.8,
			'c6h6': 20,
			'co': 10,
			'co2': 0.01111,
			'f': 0.27027,
			'ch2o': 0.27027,
		},
		colors: {
			'1_0': [colors.green, colors.greenLight, colors.yellow, colors.orange, colors.red, colors.brown],
			'2_5': [colors.green, colors.greenLight, colors.yellow, colors.orange, colors.red, colors.brown],
			'10_0': [colors.green, colors.greenLight, colors.yellow, colors.orange, colors.red, colors.brown],
			'o3': [colors.green, colors.greenLight, colors.yellow, colors.orange, colors.red, colors.brown],
			'no2': [colors.green, colors.greenLight, colors.yellow, colors.orange, colors.red, colors.brown],
			'so2': [colors.green, colors.greenLight, colors.yellow, colors.orange, colors.red, colors.brown],
			'c6h6': [colors.green, colors.greenLight, colors.yellow, colors.orange, colors.red, colors.brown],
			'co': [colors.green, colors.greenLight, colors.yellow, colors.orange, colors.red, colors.brown],
			'co2': [colors.green, colors.greenLight, colors.yellow, colors.orange, colors.red, colors.brown],
			'f': [colors.green, colors.greenLight, colors.yellow, colors.orange, colors.red, colors.brown],
			't': [colors.darkblue, colors.blue, colors.green, colors.orange, colors.red, colors.brown],
			'h': [colors.brown, colors.orange, colors.yellow, colors.green, colors.blue, colors.darkblue, colors.darkblue],
			'p': [colors.darkblue, colors.blue, colors.green, colors.orange, colors.red, colors.brown],
			'b': [colors.red, colors.yellow, colors.green],
			'sm': [colors.dropBlue5, colors.dropBlue4, colors.dropBlue3, colors.dropBlue2, colors.dropBlue1, colors.dropBlue0],

			'no': [colors.green, colors.greenLight, colors.yellow, colors.orange, colors.red, colors.brown],
			'ch2o': [colors.green, colors.greenLight, colors.yellow, colors.orange, colors.red, colors.brown],
			'db': [colors.green, colors.greenLight, colors.yellow, colors.orange, colors.red, colors.brown],
			'ch4': [colors.green, colors.greenLight, colors.yellow, colors.orange, colors.red, colors.brown],
			'hcl': [colors.green, colors.greenLight, colors.yellow, colors.orange, colors.red, colors.brown],
			'hcn': [colors.green, colors.greenLight, colors.yellow, colors.orange, colors.red, colors.brown],
			'ph': [ colors.brown, colors.red, colors.green, colors.darkblue, colors.dark ],
			'wo2': [ colors.brown, colors.red, colors.green, colors.blue, colors.darkblue ],
			'wf': [ colors.darkblue, colors.red],
			'ws': [ colors.darkblue, colors.red],
			'wl': [colors.darkgray, colors.gray, colors.darkblue, colors.yellow, colors.orange, colors.red],
			'wt': [colors.dropBlue4, colors.dropBlue3, colors.darkblue, colors.green, colors.orange, colors.red],
			'ec': [colors.green, colors.yellow, colors.brown],
			'o2p': [ colors.brown, colors.red, colors.green, colors.blue, colors.darkblue ],
			'cp': [colors.green, colors.yellow, colors.brown],
			'tb': [colors.green, colors.yellow, colors.brown],
			'alg': [colors.green, colors.brown],
		},
		unitParser: {
			't': '°C',
			'h': '%',
			'p': 'hPa',
			'b': '%',
			'sm': '%',
			'1_0': 'µg/m³',
			'2_5': 'µg/m³',
			'10_0': 'µg/m³',
			'o3': 'µg/m³',
			'no2': 'µg/m³',
			'so2': 'µg/m³',
			'c6h6': 'µg/m³',
			'co': 'mg/m³',
			'co2': 'mg/m³',
			'f': 'µg/m³',
			'wl': 'cm',

			'no': 'µg/m³',
			'ch2o': 'µg/m³',
			'wl': 'cm',
			'db': 'dB',
			'ch4': 'µg/m³',
			'hcl': 'µg/m³',
			'hcn': 'µg/m³',
			'ph': '',
			'wo2': 'mg/l',
			'wf': 'l/min',
			'ws': 'm/s',
			'wt': '°C',
			'ec': 'µS/cm',
			'o2p': '%',
			'cp': 'μg/L',
			'tb': 'NTU',
			'alg': 'cells/mL',
		},
		isPercent: {
			't': false,
			'h': false,
			'p': false,
			'b': false,
			'sm': false,
			'1_0': false,
			'wl': false,
			'2_5': true,
			'10_0': true,
			'o3': true,
			'no2': true,
			'so2': true,
			'c6h6': true,
			'co': true,
			'co2': true,
			'f': true,

			'no': false,
			'wl': false,
			'ec': false,
			'o2p': false,
			'cp': false,
			'tb': false,
			'alg': false,
			'ch2o': true,
			'db': false,
			'ch4': false,
			'hcl': false,
			'hcn': false,
			'ph': false,
			'wo2': false,
			'wf': false,
			'ws': false,
			'wt': false,
		},
		namesParser: {
			't': 'Temperatura',
			'h': 'Wilgotność',
			'p': 'Ciśnienie',
			'1_0': 'PM 1',
			'2_5': 'PM 2.5',
			'10_0': 'PM 10',
			'o3': 'O3',
			'no2': 'NO2',
			'so2': 'SO2',
			'c6h6': 'C6H6',
			'co' : 'CO',
			'co2': 'CO2',
			'f': 'HCHO',
			'b': 'Poziom baterii',
			'sm': 'Wilgotność gleby',
			'wl': 'Poziom wody',

			'no': 'NO',
			'ch2o': 'CH2O',
			'db': 'Hałas',
			'ch4': 'CH4',
			'hcl': 'HCl',
			'hcn': 'HCn',
			'ph': 'Odczyn Ph',
			'wo2': 'Natlenienie',
			'wf': 'Przepływ',
			'ws': 'Nurt',
			'wt': 'Temp. wody',
			'ec': 'Przewodność',
			'o2p': 'Natlenienie',
			'cp': 'Chlorofil',
			'tb': 'Mętność',
			'alg': 'Algi B/G',
		},
		coloredName: {
			'no-data': colors.gray,
			'very-good': colors.green,
			'good': colors.greenLight,
			'moderate': colors.yellow,
			'satisfactory': colors.orange,
			'bad': colors.red,
			'very-bad': colors.brown,
			'high': colors.blue,
			'low': colors.orange,
			'very-high': colors.darkblue,
			'very-low': colors.redOrange
		},
		coloredWaterName: {
			'no-data': colors.black,
			'alarm': colors.red,
			'warning': colors.orange,
			'high': colors.yellow,
			'average': colors.blue,
			'low': colors.gray,
		},
		intClass: {
			'very-good': 100,
			'good': 80,
			'moderate': 60,
			'satisfactory': 40,
			'bad': 20,
			'very-bad': 0,
			'high': 66,
			'low': 33,
			'very-high': 100,
			'very-low': 0
		},
		intClassFloat: {
			'no-data': 0,
			'very-good': 1,
			'good': 0.5,
			'moderate': 1,
			'satisfactory': 0.5,
			'bad': 1,
			'very-bad': 0.5,
			'high': 0.5,
			'low': 1,
			'very-high': 1,
			'very-low': 0.5
		},
	},
}, action) => {
	switch (action.type) {
		case 'SET_MAIN_DATA':
			return { ...state, ...action.data };
		default:
			return state;
	}
};

export default main;
